import _react3 from "react";
import _implementation3 from "./implementation";
var exports = {};
exports.__esModule = true;
var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _implementation = _implementation3;

var _implementation2 = _interopRequireDefault(_implementation);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = _react2.default.createContext || _implementation2.default;
exports = exports["default"];
export default exports;
export const __esModule = exports.__esModule;